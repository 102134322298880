import { defineMessages } from 'react-intl';

export const SUBSCRIPTION_GIFT_PATH = '/checkout/subscribe/gift';
export const LOGOUT_PATH = '/logout';
export const TRANSACTION_PATH = '/transactions';
export const DIRECT_TRANSACTION_PATH = '/direct_transactions';

export const LOGIN = 'login';
export const SIGNUP = 'signup';

export const STRIPE_AUTO_TRANSLATE = 'auto';

export const STANDARD_ACCOUNT_TYPE = 'standard';
export const MANAGED_ACCOUNT_TYPE = 'managed';
export const MONTHLY_GIFT = 'monthly_gift';
export const YEARLY_GIFT = 'yearly_gift';
export const GIFT = 'tvod_gift';
export const MONTHLY = 'monthly';
export const YEARLY = 'yearly';
export const PURCHASE = 'purchase';
export const SUBSCRIPTION = 'subscription';
export const RENT = 'rent';
export const PWYW = 'pwyw';
export const FVOD = 'fvod';
export const ENTER = 13;
export const SPACE = 32;
export const SVOD_OPTIONS = [MONTHLY, YEARLY];
export const TVOD_OPTIONS_WITHOUT_GIFT = [RENT, PURCHASE, PWYW];
export const TVOD_OPTIONS = [RENT, PURCHASE, PWYW, GIFT];
export const GIFT_OPTIONS = [MONTHLY_GIFT, YEARLY_GIFT, GIFT];
export const PROMO_ELIGIBLE_OPTIONS_TVOD = [RENT, PURCHASE, PWYW];
export const USD = 'USD';
export const FULL_DISCOUNT = 100;
export const DC_ZIPCODE_MAX = 20020;
export const DC_ZIPCODE_MIN = 20001;
export const VT_ZIPCODE_MAX = 5907;
export const VT_ZIPCODE_MIN = 5001;
export const TRANSACTION_STATUSES = {
  APPROVED: 'approved',
  FAILED: 'failed',
  HOLDING: 'holding',
  REQUIRES_ACTION: 'requires_action',
  REQUIRES_CONFIRMATION: 'requires_confirmation',
  PROCESSING: 'processing',
};
export const SUCCESSFUL_TRANSACTION_STATUSES = [
  TRANSACTION_STATUSES.APPROVED,
  TRANSACTION_STATUSES.HOLDING,
];

// Stripe wallets enabled as payment methods for checkout
export const WALLET_TYPES = ['apple_pay', 'google_pay'];

export const POSTAL_CODE_COUNTRIES = ['MX', 'SG'];

export const CHECKOUT_MESSAGES = defineMessages({
  name: {
    id: 'checkout.payment.name',
    defaultMessage: 'Name on card',
  },
  postalCode: {
    id: 'checkout.payment.postalcode',
    defaultMessage: 'Postal code',
  },
  processing: {
    id: 'checkout.processing',
    defaultMessage: 'Processing...*',
  },
  completePreorder: {
    id: 'checkout.preorder.complete',
    defaultMessage: 'Complete preorder',
  },
  redeemNow: {
    id: 'checkout.tvod.redeemnow',
    defaultMessage: 'Redeem now',
  },
  startSubscription: {
    id: 'checkout.svod.start',
    defaultMessage: 'Start subscription',
  },
  completePurchase: {
    id: 'checkout.payment.complete',
    defaultMessage: 'Complete purchase',
  },
  accountRequired: {
    id: 'checkout.account.required',
    defaultMessage: 'An account is required to access your purchases.',
  },
});

const BUY_CATEGORY = 'buy';
const PURCHASE_CATEGORY = 'purchase';
const RENT_CATEGORY = 'rent';
const SUBSCRIPTION_CATEGORY = 'subscription';
const MONTHLY_CATEGORY = 'monthly';
const YEARLY_CATEGORY = 'yearly';
const GIFT_CATEGORY = 'gift';

export const CONVERSION_EVENT_CATEGORY_MAP = {
  [PURCHASE]: {
    item_category: BUY_CATEGORY,
    item_category2: PURCHASE_CATEGORY,
  },
  [PWYW]: {
    item_category: BUY_CATEGORY,
    item_category2: PURCHASE_CATEGORY,
  },
  [RENT]: {
    item_category: BUY_CATEGORY,
    item_category2: RENT_CATEGORY,
  },
  [MONTHLY]: {
    item_category: SUBSCRIPTION_CATEGORY,
    item_category2: MONTHLY_CATEGORY,
  },
  [YEARLY]: {
    item_category: SUBSCRIPTION_CATEGORY,
    item_category2: YEARLY_CATEGORY,
  },
  [GIFT]: {
    item_category: GIFT_CATEGORY,
    item_category2: PURCHASE_CATEGORY,
  },
  [MONTHLY_GIFT]: {
    item_category: GIFT_CATEGORY,
    item_category2: MONTHLY_CATEGORY,
  },
  [YEARLY_GIFT]: {
    item_category: GIFT_CATEGORY,
    item_category2: YEARLY_CATEGORY,
  },
};
